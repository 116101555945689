import React from "react"
import Index from "@pages/regulation.js";

const RegulationJa = () => {
  const setLang = 'ja';
  return (
  <><Index langType={setLang}/></>
  )
}
export default RegulationJa
